/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: script.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:01
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:37:28
 Modificado por: pedro.rosa
*/
import ferramentas from "@/components/ferramentas";
import botoesAcao from "@/components/botoesAcao";
import axios from "axios";
import moment from "moment";
import { tabelaCadastroClientes } from "./table";
import {
  opcoesTipo,
  itensFerramentas,
  colunas,
  importsObrigatorios,
  campos,
} from "./colunas";
export default {
  components: { ferramentas, botoesAcao },
  data() {
    return {
      opcoesTipo,
      itensFerramentas,
      colunas,
      importsObrigatorios,
      campos,
      arquivo: tabelaCadastroClientes,
      nomeArquivo: "SaaS - Template cadastro de clientes.xlsx",
      flexgrid: {},
      abaEscolhida: "",
      keyTabelaWijmo: 0,
      dados: [],
      abas: [],
    };
  },
  computed: {
    campoCEP() {
      return this.campos[0].fields[8].model;
    },
  },
  watch: {
    campoCEP: {
      deep: true,
      handler() {
        if (this.campos[0].fields[8].model.length) {
          if (this.campos[0].fields[8].model.length === 10) {
            const CEP = this.campos[0].fields[8].model
              .replace(".", "")
              .replace("-", "");
            this.campos[0].fields.forEach((campo, i) => {
              if (i == 9 || i == 14 || i == 13 || i == 12) {
                campo.loading = true;
              }
            });
            axios
              .get(`https://brasilapi.com.br/api/cep/v1/${CEP}`)
              .then((respostaCep) => {
                this.campos[0].fields[9].model = respostaCep.data.street;
                this.campos[0].fields[14].model = respostaCep.data.neighborhood;
                this.campos[0].fields[13].model = respostaCep.data.city;
                this.campos[0].fields[12].model = respostaCep.data.state;
                this.campos[0].fields.forEach((campo, i) => {
                  if (i == 9 || i == 14 || i == 13 || i == 12) {
                    campo.loading = false;
                  }
                });
              });
          }
        }
      },
    },
  },
  methods: {
    async criaObj(item) {
      // let data = item.ce048_dt_incemp.split('/')
      const usr = await this.getUser();
      const ent = {
        _rC001_TP_PESSOA:
          this.unmask(item.ce001_nr_cpfcnpj).length <= 11 ? 1 : 2,
        _rCE001_NR_CPFCNPJ: this.unmask(item.ce001_nr_cpfcnpj),
        _rCE001_NR_CLIENT: item.ce001_nr_client ? item.ce001_nr_client : null,
        _rCE001_CD_SITUAC: item.ce001_cd_situac ? item.ce001_cd_situac : null,
        _rCE001_ID_MALAD: item.ce001_id_malad !== "" ? 1 : 2,
        _rCE001_NM_CLIENT: item.ce001_nm_client ? item.ce001_nm_client : null,
        _rCE001_DT_NASCCL: item.ce001_dt_nasccl_mdl
          ? this.backToISOFormat(item.ce001_dt_nasccl_mdl)
          : null,
        _rCE001_TP_DOC: item.ce001_tp_doc ? item.ce001_tp_doc : null,
        _rCE001_NR_DOC: item.ce001_nr_doc ? item.ce001_nr_doc : null,
        _rCE001_SG_ORGEMI: item.ce001_sg_orgemi ? item.ce001_sg_orgemi : null,
        _rCE001_DT_EXPDOC: item.ce001_dt_expdoc
          ? this.backToISOFormat(item.ce001_dt_expdoc)
          : null,
        _rCE001_NM_END: item.ce001_nm_end ? item.ce001_nm_end : null,
        _rCE001_NR_END: item.ce001_nr_end ? item.ce001_nr_end : null,
        _rCE001_DS_COMPL: item.ce001_ds_compl ? item.ce001_ds_compl : null,
        _rCE001_NM_BAIRRO: item.ce001_nm_bairro ? item.ce001_nm_bairro : null,
        _rCE001_NM_CIDADE: item.ce001_nm_cidade ? item.ce001_nm_cidade : null,
        _rCE001_CD_MUNICI: item.ce001_cd_munici ? item.ce001_cd_munici : null,
        _rCE001_SG_ESTADO: item.ce001_sg_estado ? item.ce001_sg_estado : null,
        _rCE001_NR_CEP: item.ce001_nr_cep
          ? this.unmaskCEP(item.ce001_nr_cep)
          : null,
        _rCE001_NR_DDDTEL: item.ce001_nr_dddtel
          ? this.unmaskTelCel(item.ce001_nr_tel).ddd
          : null,
        _rCE001_NR_TEL: item.ce001_nr_tel
          ? this.unmaskTelCel(item.ce001_nr_tel).number
          : null,
        _rCE001_NR_DDDCEL: item.ce001_nr_dddcel
          ? this.unmaskTelCel(item.ce001_nr_tel).ddd
          : null,
        _rCE001_NR_CEL: item.ce001_nr_cel
          ? this.unmaskTelCel(item.ce001_nr_cel).number
          : null,
        _rCE001_NM_EMAIL: item.ce001_nm_email ? item.ce001_nm_email : null,
        _rCE001_DT_CASAM: item.ce001_dt_casam
          ? this.backToISOFormat(item.ce001_dt_casam)
          : null,
        _rCE001_DS_CARCLI: item.ce001_ds_carcli ? item.ce001_ds_carcli : null,
        _rCE001_DT_INCCAD: parseInt(
          new Date()
            .toISOString()
            .substr(0, 10)
            .replaceAll("-", ""),
        ), // NOT NULL
        _rCE001_DT_ATUCAD: parseInt(
          new Date()
            .toISOString()
            .substr(0, 10)
            .replaceAll("-", ""),
        ), // NOT NULL
        _rCE001_CD_OPESIS: usr.base.us01_nr_usr,
      };
      return ent;
    },
    async enviaDados(ent) {
      try {
        this.startLoader();
        await this.postSP("SP_CE360903003", ent);
        this.notification().success({mensagem:"Informações salvas"});
      } catch (err) {
        this.notification().error({erro: "Erro ao salvar informações"});
      } finally {
        this.stopLoader();
      }
    },
    async addNovoItem(item) {
      let ent = await this.criaObj(item);
      await this.enviaDados([ent]);
    },
    async editarItem(item) {
      let ent = await this.criaObj(item);
      await this.enviaDados([ent]);
    },
    deletarDaTabela(e) {
      e.forEach((f) => {
        let index = this.dados.findIndex((item) => item == f);
        if (index !== -1) {
          this.dados.splice(index, 1);
        }
      });
      this.abas.forEach((f, i) => {
        if (f.pesquisa.length == 0) {
          this.abas.splice(i, 1);
        }
      });
      this.keyTabelaWijmo++;
    },
    initializeGrid(flexgrid) {
      this.flexgrid = flexgrid;
    },
    escolheAba(aba) {
      this.abaEscolhida = aba;
    },
    alteraVendFerramentas(ret) {
      this.dados = this.dados.map((d) => {
        if (d.isSelected) {
          return {
            ...d,
            ce001_nm_client: ret.cliente ? ret.cliente : d.ce001_nm_client,
            ce001_nm_email: ret.email ? ret.email : d.ce001_nm_email,
            ce001_nr_tel_mdl: ret.telefone ? ret.telefone : d.ce001_nr_tel_mdl,
          };
        } else {
          return d;
        }
      });
    },
    async mountObj(obj) {
      try {
        this.startLoader();
        let clientes = await this.chamaMulti("SP_CE360903007", obj);
        this.getVended(clientes);
      } catch {
        this.notification().error({erro: "Erro ao trazer os clientes"});
      } finally {
        this.stopLoader();
      }
    },
    async getVended(clientesDB) {
      try {
        this.startLoader();
        clientesDB = clientesDB ? clientesDB.database : null;
        if (clientesDB) {
          this.dados = clientesDB.map((p, i) => {
            return {
              ...p,
              isSelected: false,
              itemKey: i,
              ce001_dt_nasccl_mdl: p.ce001_dt_nasccl
                ? this.maskData(p.ce001_dt_nasccl)
                : null,
              ce001_nr_cep: p.ce001_nr_cep
                ? this.maskCep(p.ce001_nr_cep)
                : null,
              ce001_nr_cpfcnpj_mdl: p.ce001_nr_cpfcnpj
                ? this.maskCPFCNPJ(p.ce001_nr_cpfcnpj)
                : null,
              ce001_nr_tel_mdl:
                p.ce001_nr_cpfcnpj && p.ce001_nr_dddtel
                  ? this.maskTelefone(p.ce001_nr_dddtel, p.ce001_nr_tel)
                  : null,
            };
          });
        }
        const objAba = {
          nome_aba: `${this.$t("TelaLojas.pesquisa")} ${this.abas.length +
            1} - ${moment()
            .locale("pt-br")
            .format("l")} - ${this.$t("TelaLojas.quantidade")}: ${
            this.dados.length
          }`,
          pesquisa: clientesDB ? this.dados : [],
          remove: false,
          backup: clientesDB ? this.dados : [],
        };
        this.abas.push(objAba);
      } catch {
        this.notification().error({erro: "Erro ao trazer os produtos"});
      } finally {
        this.stopLoader();
      }
    },
    erroBuscaPorFiltro() {
      this.notification().error({erro: "O filtro possui algum erro."});
    },
    salvaDadosTabela() {
      this.startLoader();
      let clientes = [];
      this.dados.forEach(async (f) => {
        if (f.isSelected) {
          clientes.push(await this.criaObj(f));
        }
      });
      setTimeout(async () => {
        if (clientes.length !== 0) {
          await this.enviaDados(clientes);
        } else {
          this.notification().warn({ mensagem: "Selecione os clientes para salvar"});
        }
      }, 1000);
    },
    /* eslint-disable */
    dadosConfirmados(dadosCorretos, dadosIncorretos, nomeArquivo) {},
    /* eslint-disable */
    aplicarBackup() {
      const backup = this.abas.find((aba) => aba.nome_aba === this.abaEscolhida)
        .backup;
      this.dados = backup;
    },
  },
};
