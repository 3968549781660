/**
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: colunas.js
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:01
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:37:23
 Modificado por: pedro.rosa
*/
module.exports = {
  opcoesTipo: [
    {
      value: "CE001_NR_CLIENT",
      text: "Nr. cliente",
      type: ["number"],
    },
    {
      value: "CE001_NM_CLIENT",
      text: "Nome",
      type: ["text"],
    },
    {
      value: "CE001_NR_CPFCNPJ",
      text: "CPF / CNPJ",
      type: ["text"],
    },
    {
      value: "CE001_NR_TEL",
      text: "Telefone",
      type: ["number"],
    },
  ],
  itensFerramentas: [
    {
      text: "Nome cliente",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: false,
        nome: "cliente",
        adicionar: false,
        filtro: true,
        itens: false,
        objKey: "ce001_nm_client",
      },
    },
    {
      text: "Email",
      value: {
        type: "textfield",
        SP: null,
        opcoesTipo: null,
        nome: "email",
        adicionar: false,
        filtro: true,
        objKey: "ce001_nm_email",
      },
    },
    {
      text: "Telefone",
      value: {
        type: "textfield",
        SP: false,
        opcoesTipo: false,
        nome: "telefone",
        adicionar: false,
        filtro: true,
        itens: false,
        objKey: "ce001_nr_tel",
      },
    },
  ],
  colunas: [
    {
      cabecalho: "Nr.",
      larg: 80,
      valor: "ce001_nr_client",
      align: "right",
    },
    {
      cabecalho: "CPF/CNPJ",
      larg: 150,
      valor: "ce001_nr_cpfcnpj_mdl",
      align: "center",
    },
    {
      cabecalho: "Nm. cliente",
      larg: "*",
      valor: "ce001_nm_client",
      align: "left",
    },
    {
      cabecalho: "Email",
      larg: 250,
      valor: "ce001_nm_email",
      align: "left",
    },
    {
      cabecalho: "CEP",
      larg: 150,
      valor: "ce001_nr_cep",
      align: "left",
    },
    {
      cabecalho: "Cidade",
      larg: 200,
      valor: "ce001_nm_cidade",
      align: "left",
    },
    {
      cabecalho: "Estado",
      larg: 100,
      valor: "ce001_sg_estado",
      align: "center",
    },
    {
      cabecalho: "Telefone",
      larg: 150,
      valor: "ce001_nr_tel_mdl",
      align: "left",
    },
  ],
  importsObrigatorios: [
    "CPF/CNPJ",
    "Nr. Cliente",
    "Nm. Cliente",
    "Situação",
    "Tipo docto.",
    "Nr. do docto.",
    "Dt. exp...",
    "Órgão emissor",
    "CEP",
  ],
  campos: [
    {
      DIVISION: "",
      fields: [
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          mask: ["###.###.###-##", "##.###.###/####-##"],
          maxLength: 18,
          rules: [(v) => !!v || "Insira o CPF/CNPJ do cliente"],
          nome: "CPF/ CNPJ",
          cols: 3,
          required: true,
          chave: "ce001_nr_cpfcnpj",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Nr. do cliente",
          cols: 3,
          required: false,
          chave: "ce001_nr_client",
          disabled: true,
          maxLength: 7,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Nome",
          rules: [(v) => !!v || "Insira o nome do cliente"],
          cols: 4,
          required: true,
          chave: "ce001_nm_client",
          maxLength: 40,
        },
        {
          model: 1,
          vuetify: "autocomplete",
          items: [
            { text: "Liberado", value: 1 },
            { text: "Pendente", value: 2 },
            { text: "Sem tradição", value: 3 },
            { text: "Vencido", value: 4 },
            { text: "Bloqueado", value: 5 },
            { text: "Prospecção", value: 6 },
            { text: "Inativo", value: 7 },
          ],
          nome: "Situação",
          cols: 2,
          required: true,
          chave: "ce001_cd_situac",
        },
        {
          model: "",
          vuetify: "autocomplete",
          items: [
            { text: "Carteira de identidade", value: 1 },
            { text: "Carteira de motorista", value: 2 },
            { text: "Carteira de trabalho", value: 3 },
            { text: "Passaporte", value: 4 },
            { text: "Certidão de nascimento", value: 5 },
            { text: "(GIP) - Grau de Incentivo a Participação", value: 6 },
            { text: "Carteira do Conselho", value: 7 },
          ],
          nome: "Tipo docto.",
          cols: 2,
          required: false,
          chave: "ce001_tp_doc",
          maxLength: 3,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Nr. do docto.",
          rules: [(v) => !!v || "Insira o nr. do docto. do cliente"],
          cols: 3,
          required: true,
          chave: "ce001_nr_doc",
          maxLength: 20,
        },
        {
          model: "",
          vuetify: "datepicker",
          active: false,
          nome: "Dt. exp...",
          icon: "date_range",
          cols: 3,
          required: false,
          chave: "ce001_dt_expdoc",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Órgão emissor",
          cols: 4,
          required: false,
          chave: "ce001_sg_orgemi",
          maxLength: 20,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "CEP",
          mask: "##.###-###",
          cols: 2,
          required: false,
          chave: "ce001_nr_cep",
          maxLength: 10,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Endereço",
          cols: 5,
          required: false,
          chave: "ce001_nm_end",
          maxLength: 40,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Nr.",
          cols: 2,
          required: false,
          chave: "ce001_nr_end",
          maxLength: 7,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Compl.",
          cols: 3,
          required: false,
          chave: "ce001_ds_compl",
          maxLength: 20,
        },
        {
          model: "",
          vuetify: "autocomplete",
          nome: "Estado",
          items: [
            "AC",
            "AL",
            "AP",
            "AM",
            "BA",
            "CE",
            "ES",
            "GO",
            "MA",
            "MT",
            "MS",
            "MG",
            "PA",
            "PB",
            "PR",
            "PE",
            "PI",
            "RJ",
            "RN",
            "RS",
            "RO",
            "RR",
            "SC",
            "SP",
            "SE",
            "TO",
            "DF",
          ],
          cols: 2,
          required: false,
          chave: "ce001_sg_estado",
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Cidade",
          cols: 3,
          required: false,
          chave: "ce001_nm_cidade",
          maxLength: 40,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Bairro",
          cols: 3,
          required: false,
          chave: "ce001_nm_bairro",
          maxLength: 30,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "IBGE",
          cols: 4,
          required: false,
          chave: "ce001_cd_munici",
          maxLength: 7,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Email",
          cols: 3,
          required: false,
          chave: "ce001_nm_email",
          maxLength: 50,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Telefone",
          mask: "(##) ####-####",
          cols: 3,
          required: false,
          chave: "ce001_nr_tel",
          maxLength: 14,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "tel",
          nome: "Celular",
          mask: "(##) #####-####",
          cols: 3,
          required: false,
          chave: "ce001_nr_cel",
          maxLength: 15,
        },
        {
          model: "",
          vuetify: "checkbox",
          type: "tel",
          nome: "Receber mala direta",
          cols: 3,
          required: false,
          chave: "ce001_id_malad",
        },
        {
          model: "",
          vuetify: "datepicker",
          active: false,
          nome: "Nasc.",
          icon: "date_range",
          cols: 3,
          required: true,
          chave: "ce001_dt_nasccl_mdl",
          maxLength: 10,
        },
        {
          model: "",
          vuetify: "datepicker",
          active: false,
          nome: "Casamento",
          cols: 3,
          icon: "date_range",
          required: false,
          chave: "ce001_dt_casam",
          maxLength: 10,
        },
        {
          model: "",
          vuetify: "textfield",
          type: "text",
          nome: "Profissão",
          cols: 6,
          required: false,
          chave: "ce001_ds_carcli",
          maxLength: 40,
        },
      ],
    },
  ],
};
